import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import withSlider from './settings';
import Loader from '../Placeholder/loader';

import './style.css';

import loadComponents from '../Loadable';
import loadLibrary from '../Loadable/library';

const Img = loadLibrary('gatsby-image');

const Overlay = loadComponents('slider-overlay');
const Arrow = loadComponents('slider-arrow');
const Inner = loadComponents('slider-inner');
const Dots = loadComponents('slider-dots');

const Fallback = () => <Loader className="singleSlide" />;

const Slider = props => {
  const data = useStaticQuery(graphql`
    {
      wp {
        slider {
          slider {
            homepageSlider {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              # sliderContent {
              #   title
              #   text
              #   buttonText
              #   buttonLink
              # }
            }
            navigationOptions {
              enableArrows
              enableDots
              slideDisplayTime
            }
            stylingOptions {
              enableImageOverlay
              sliderAnimation
            }
          }
        }
      }
    }
  `);
  return <SliderLayout data={data} {...props} />;
};

export default withSlider(Slider);

class SliderLayout extends React.Component {
  _initSlider = false;

  constructor(props) {
    super(props);
    const { data } = this.props;
    this.slider = data.wp.slider.slider.homepageSlider;
    this.options = data.wp.slider.slider;
  }

  componentDidMount() {
    this.sliderInit();
  }

  componentWillUnmount() {
    this._initSlider = false;
  }

  sliderInit = () => {
    if (!this._initSlider) {
      this._initSlider = true;
      const { setOptions } = this.props;

      const timer = this.options.navigationOptions.slideDisplayTime;
      const length = this.slider.length;

      setOptions(timer, length);
    }
  };

  render() {
    const {
      active,
      width,
      // pauseAutoplay,
      // setAutoplay,
      prevSlide,
      nextSlide,
      changeSlide,
    } = this.props;

    const slider = this.slider;
    const options = this.options;

    return (
      <div
        className="sliderOuter"
        // onMouseEnter={pauseAutoplay}
        // onMouseLeave={setAutoplay}
        role="presentation"
      >
        {options.navigationOptions.enableArrows && (
          <Arrow dir="prev" label="Previous Slider" onClick={prevSlide} />
        )}
        <Inner
          width={width}
          active={active}
          count={slider.length}
          style={{ width: !this._initSlider ? '100%' : '' }}
          fallback={<Fallback />}
        >
          {slider.map((item, i) => {
            const key = `sliderItem${i}`;
            return (
              <div key={key} className="singleSlide" style={{ ...singleStyle }}>
                <Img
                  image={item.image.localFile.childImageSharp.gatsbyImageData}
                  alt=""
                  className="sliderImage"
                  style={imageStyle}
                  fallback={<Fallback />}
                />
                {options.stylingOptions.enableImageOverlay && <Overlay />}
              </div>
            );
          })}
        </Inner>
        {options.navigationOptions.enableArrows && (
          <Arrow dir="next" label="Next Slider" onClick={nextSlide} />
        )}
        {options.navigationOptions.enableDots && (
          <Dots
            slider={slider}
            position="30px"
            active={active}
            changeSlide={changeSlide}
          />
        )}
      </div>
    );
  }
}

const singleStyle = {
  flex: `0 0 100vw`,
  position: `relative`,
};

const imageStyle = {
  position: `absolute`,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};
