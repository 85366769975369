import React from 'react';

function withSlider(WrappedComponent) {
  class SliderSettings extends React.Component {
    _isMounted = false;

    constructor(props) {
      super(props);
      this.state = {
        active: 0,
        width: 0,
        sliding: false,
        drag: 0,
        timer: 'disabled',
        slides: 0,
      };
      this.setWidth = this.setWidth.bind(this);
    }

    componentDidMount() {
      this._isMounted = true;
      window.addEventListener('resize', this.setWidth);
      this.setWidth();
    }

    componentWillUnmount() {
      this._isMounted = false;
      window.removeEventListener('resize', this.setWidth);
      // this.pauseAutoplay();
    }

    setWidth() {
      // this.pauseAutoplay();
      if (this._isMounted) {
        this.setState({
          width: typeof window !== 'undefined' && window.innerWidth,
        });
      }
    }

    setOptions = (autoplay, count) => {
      this.setState(
        {
          timer: autoplay,
          slides: count,
        },
        /* () => {
          this.setAutoplay();
        }, */
      );
    };

    /* setAutoplay = () => {
      const { timer } = this.state;
      if (timer !== 'disabled') {
        this.autoPlay = setInterval(() => {
          this.nextSlide();
        }, Number(timer));
      }
    };

    pauseAutoplay = () => {
      clearInterval(this.autoPlay);
    }; */

    changeSlide = i => {
      if (this._isMounted) {
        this.setState({ sliding: true, active: i });
      }
    };

    nextSlide = () => {
      if (this._isMounted) {
        const { active, slides } = this.state;
        this.setState({
          sliding: true,
          active: active === slides - 1 ? 0 : active + 1,
        });
      }
    };

    prevSlide = () => {
      if (this._isMounted) {
        const { active, slides } = this.state;
        this.setState({
          sliding: true,
          active: active === 0 ? slides - 1 : active - 1,
        });
      }
    };

    resetSlide = () => {
      if (this._isMounted) {
        this.setState({ sliding: true });
      }
    };

    endSlide = () => {
      if (this._isMounted) {
        this.setState({ sliding: false });
      }
    };

    setDrag = x => {
      if (this._isMounted) {
        this.setState({ drag: x });
      }
    };

    handleDrag = () => {
      const { width, active, drag } = this.state;
      const current = -width * active;
      const distance = 200;
      if (drag < current - distance) {
        this.nextSlide();
      }
      if (drag > current + distance) {
        this.prevSlide();
      }
      if (drag < current + distance || drag > current - distance) {
        this.resetSlide();
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          setOptions={this.setOptions}
          // pauseAutoplay={this.pauseAutoplay}
          // setAutoplay={this.setAutoplay}
          changeSlide={this.changeSlide}
          prevSlide={this.prevSlide}
          nextSlide={this.nextSlide}
          endSlide={this.endSlide}
          setDrag={this.setDrag}
          handleDrag={this.handleDrag}
        />
      );
    }
  }
  return SliderSettings;
}

export default withSlider;
